<template>
  <div class="app-container">
    <el-row>
      <el-col :span="6">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addRole"
          >{{ $t("roleManager.addRole") }}</el-button
        >
      </el-col>
      <el-col :span="6" :offset="12">
        <div class="filter-container" align="right">
          <tenant-select
            style="width: 200px"
            size="small"
            class="filter-item"
            @change="queryUser"
            v-model="role.listQuery.tenantId"
            key="searchTenant"
          ></tenant-select>
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            icon="el-icon-search"
            :loading="loading"
            @click="queryUser"
            >{{ $t("commons.search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      :data="roleList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column prop="tenant" :label="$t('commons.tenant')">
        <template slot-scope="scope">
          <span>{{ scope.row.tenantName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" :label="$t('roleManager.name')">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleNameDesc" :label="$t('roleManager.desc')">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input
              v-model="scope.row.roleNameDesc"
              maxlength="33"
              :show-word-limit="true"
              size="small"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            />
          </template>
          <span v-else>{{ scope.row.roleNameDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.actions')"
        width="160"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <div class="ruge-buttons">
              <el-button type="text" @click="updateRole(scope.row)">{{
                $t("commons.save")
              }}</el-button>
              <el-button type="text" @click="cancelEditRole(scope.row)">{{
                $t("commons.cancel")
              }}</el-button>
            </div>
          </template>
          <template v-else>
            <div class="ruge-buttons">
              <el-button type="text" @click="allotPermission(scope.row)">{{
                $t("permission.grantPermission")
              }}</el-button>
              <el-button type="text" @click="editRole(scope.row)">{{
                $t("commons.edit")
              }}</el-button>
              <template
                v-if="
                  scope.row.roleId > 1 &&
                  !['TENANT_MANAGE', 'SYS_DEFAULT'].includes(scope.row.roleCode)
                "
              >
                <el-button
                  type="text"
                  style="color: #ff0000"
                  @click="deleteRole(scope.row)"
                  >{{ $t("commons.delete") }}</el-button
                >
              </template>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="role.total > 0"
      :total="role.total"
      :page.sync="role.listQuery.current"
      :limit.sync="role.listQuery.rowCount"
      @pagination="getRolePage"
    />
    <!-- 增加角色 -->
    <el-dialog :visible.sync="editMode" width="30%" :before-close="quitAddRole">
      <template slot="title">
        <title-icon />{{ $t("roleManager.addRole") }}
      </template>
      <el-form
        label-position="top"
        :model="form"
        ref="roleForm"
        :rules="roleRule"
      >
        <el-form-item :label="$t('commons.tenant')" prop="tenantId">
          <tenant-select
            v-model="form.tenantId"
            key="editTenant"
          ></tenant-select>
        </el-form-item>
        <el-form-item :label="$t('roleManager.name')" prop="roleName">
          <el-input
            v-model="form.roleName"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('roleManager.desc')" prop="roleNameDesc">
          <el-input
            v-model="form.roleNameDesc"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quitAddRole()">{{ $t("commons.cancel") }}</el-button>
        <el-button type="primary" @click="saveRole()">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/role");
import TenantSelect from "@/components/TenantSelect";
import Pagination from "@/components/Pagination";

export default {
  name: "RoleSysManager",
  components: { TenantSelect, Pagination },
  data() {
    return {
      loading: true,
      roleList: [],
      role: {
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          tenantId: -1,
        },
      },
      editMode: false,
      currentRow: {
        roleName: "",
        tenantId: null,
        roleId: null,
      },
      form: {
        tenantId: -1,
        roleName: "",
        roleNameDesc: "",
      },
      roleRule: {
        roleName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        tenantId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getUrlQuerys();
  },
  computed: {
    roleTab() {
      return defineTab.roleTab;
    },
  },
  methods: {
    getUrlQuerys() {
      //获取连接上携带的参数
      const qData = this.$route.query;
      for (let key in qData) {
        qData[key] && this.updateSingleKey(key, qData);
      }
      this.queryUser();
    },
    updateSingleKey(key, datas) {
      const value = datas[key];
      switch (key) {
        case "current":
          this.role.listQuery.current = Number(value);
          break;
        case "rowCount":
          this.role.listQuery.rowCount = Number(value);
          break;
        case "tenantId":
          this.role.listQuery.tenantId = Number(value);
          break;
      }
    },
    queryUser() {
      this.role.listQuery.current = 1;
      let str = "";
      let dataObj = { ...this.$route.query, ...this.role.listQuery };
      for (let keys in dataObj) {
        let val = dataObj[keys];
        if (keys && val) {
          str += keys + "=" + val + "&";
        }
      }
      this.$router.push(this.$route.path + "?" + str);
      this.getRolePage();
    },
    getRolePage() {
      this.loading = true;
      manager
        ._sys_getRolePage(this.role.listQuery)
        .then((response) => {
          this.roleList = response.rows.map((v) => {
            this.setOrgRole(v);
            return v;
          });
          this.role.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    editRole(row) {
      this.$set(row, "edit", true);
    },
    // 删除用户
    deleteRole(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 调用服务
          manager
            ._sys_deleteRole({ tenantId: row.tenantId, roleId: row.roleId })
            .then(() => {
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              if (this.role.total % this.role.listQuery.rowCount == 1) {
                this.role.listQuery.current = this.role.listQuery.current - 1;
              }
              // 刷新列表
              this.queryUser();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 更新角色
    updateRole(row) {
      manager
        ._sys_updateRole(row)
        .then(() => {
          row.edit = false;
          this.setOrgRole(row);
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelEditRole(row) {
      this.resetOrgRole(row);
      row.edit = false;
    },
    setOrgRole(row) {
      row.orgRoleName = row.roleName;
      row.orgRoleNameDesc = row.roleNameDesc;
    },
    resetOrgRole(row) {
      row.roleName = row.orgRoleName;
      row.roleNameDesc = row.orgRoleNameDesc;
    },
    // 添加角色
    addRole() {
      this.editMode = true;
    },
    //退出新增角色
    quitAddRole() {
      this.$refs.roleForm.resetFields();
      this.editMode = false;
    },
    //保存角色
    saveRole() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          manager
            ._sys_saveRole(this.form)
            .then(() => {
              this.editMode = false;
              this.form.tenantId = null;
              this.form.roleName = null;
              this.form.roleNameDesc = null;
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
              this.queryUser();
            })
            .catch(() => {});
        }
      });
    },
    // 分配权限
    allotPermission(row) {
      this.$router.push({
        path: "/sys/permission/role/detail",
        query: {
          roleName: row.roleName,
          roleCode: row.roleCode,
          roleId: row.roleId,
          tenantId: row.tenantId,
        },
      });
    },
  },
};
</script>

